import { VehiclePlayIcon } from '@/views/monitor/monitorComponents/utils/mapUtil'
import moment from 'moment'
import { getColor } from './TrackColor'
export default {
  data() {
    return {
      startIndex: 0,
      path: [],
      lineWidth: 15,
      pixelPath: [],
      curStatus: 0,
      curInfoPoint: {},
      curInfoTitle: '',
      showInfo: false,
      trackType: 'green',
      lastChangeTickcount: new Date(),
      lushuConfig: {
        playIcon: VehiclePlayIcon,
        // 控制车辆方向
        enableRotation: true,
        // 轨迹播放时ICON上方显示的文字
        defaultContent: '',
        // 图标大小
        iconSize: { width: 52, height: 26 },
        // 图标的定位点相对于图标左上角的偏移值
        anchorSize: { width: 27, height: 13 }
      },
      currentSpeed: 100,
      // 绘制历史轨迹折线参数
      normalTrackPolylineParams: {
        strokeColor: 'red',
        strokeOpacity: '0.5',
        strokeWeight: '2',
        editing: 'false'
      },
      abNormalTrackPolylineParams: {
        strokeColor: 'red',
        strokeOpacity: '0.5',
        strokeWeight: '2',
        strokeStyle: 'dashed',
        editing: 'false'
      },

      // 轨迹折线覆盖物
      trackPloylineOverlays: [],
      // 当前轨迹分组数据
      currentTrackGroupArray: [],
      isSyncVideo: false,
      isShowSignal: false
    }
  },
  mounted() {},
  beforeDestroy() {
    console.log('destroy')
  },
  methods: {
    deleteLushuMarker() {
      this.lushu && this.lushu.deleteMarker()
    },
    // LuShu
    initLushu(data) {
      console.log('初始化路书')
      // 路书脚本加载完成之后才能读取到对象
      this.BMapLib = window.BMapLib
      // 路书坐标集
      this.path = data
      this.setPixelPath()
      // 初始化路书
      this.initLuShuMap()
    },
    // LuShu版本
    initLuShuMap() {
      const { BMapLib, path, trackBMap, trackMap, currentSpeed } = this
      const BMap = trackBMap
      const map = trackMap
      // 路书初始化
      console.log('LuShu initLuShuMap' + new Date())
      const isSyncVideo = this.lushu ? this.lushu.isSyncVideo : this.isSyncVideo
      const opts = {
        landmarkPois: [],
        geodesic: true,
        autoCenter: true,
        icon: new BMap.Icon(
          this.lushuConfig.playIcon,
          new BMap.Size(this.lushuConfig.iconSize.width, this.lushuConfig.iconSize.height),
          {
            anchor: new BMap.Size(this.lushuConfig.anchorSize.width, this.lushuConfig.anchorSize.height)
          }
        ),
        speed: currentSpeed,
        defaultContent: this.lushuConfig.defaultContent,
        enableRotation: this.lushuConfig.enableRotation
      }
      // 路书使用单例
      if (!this.lushu) {
        this.lushu = new BMapLib.LuShu(BMap, map, path, opts)
      } else {
        this.lushu.reset(BMap, map, path, opts)
      }

      // 判断是否有摄像头
      if (this.params && this.isHaveCamera(this.params.currentTrackObject)) {
        this.lushu.setSyncVideo(isSyncVideo)
      } else {
        this.lushu.setSyncVideo(false)
      }

      if (path.length > 0) {
        this.lushu.initMarker(0)
      }
      setTimeout(() => {
        window.PubSub.publish('afterTrackIniting')
      }, 1000)
    },

    isHaveCamera(obj) {
      if (obj) {
        if (!obj.cameras || obj.cameras.length === 0) {
          if (obj.rtc && obj.rtc.vehicleCameraDeviceInfos.length > 0) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }
    },

    // 地图轨迹播放
    mapTrackPlay(status, oldStatus) {
      console.log(`LuShu mapTrackPlay status=${status}, oldStatus=${oldStatus}`)
      if (!this.lushu) {
        return
      }
      this.curStatus = status
      if (status === 1) {
        let startIndex = this.startIndex
        if (oldStatus === 0) {
          // 如果是初始状态，则从0开始运动
          startIndex = 0
        }
        this.lushu.start(startIndex)
      } else {
        this.lushu.pause()
      }
    },

    // 地图轨迹播放跳转
    mapTrackPlayJumpTo(val, status) {
      console.log(`LuShu mapTrackPlayJumpTo, val=${val},status=${status}`)
      const { toPoint } = this
      toPoint(val, status)
    },

    // 地图轨迹播放暂停
    mapTrackPlayPause() {
      console.log(`LuShu mapTrackPlayPause`)
      this.lushu && this.lushu.pause()
    },

    // 地图轨迹播放停止
    mapTrackPlayStop() {
      console.log(`LuShu mapTrackPlayStop`)
      this.lushu && this.lushu.stop()
    },

    // 设置地图轨迹播放速度
    mapSetTrackPlaySpeed(val) {
      console.log('LuShu mapSetTrackPlaySpeed', val)
      this.currentSpeed = val * 100
      // 再设置
      this.lushu &&
        this.lushu._setOptions({
          speed: this.currentSpeed
        })
      this.lushu.notifyChangeSpeed()
    },
    mapSetSyncVideo(val) {
      console.log('LuShu mapSetSyncVideo', val)
      this.lushu && this.lushu.setSyncVideo(val)
      this.isSyncVideo = val
    },

    // 设置显示信号强弱
    mapSetShowSignal(val) {
      this.isShowSignal = val
      this.drawTrackPolyline()
    },

    toPoint(index, status) {
      // const { map } = this
      const curTime = new Date()
      const tickcount = curTime.getTime() - this.lastChangeTickcount.getTime()
      // 1秒之内不允许再次启动轨迹播放
      if (tickcount < 1000) {
        return
      }
      this.lastChangeTickcount = curTime
      // 开始运动位置
      this.startIndex = index
      this.lushu.setPosition(index)
      // if (status !== 2) {
      //   // console.log('路线点击切换')
      //   // this.lushu.start(index, true)
      //   this.lushu.setPosition(index)
      // } else {
      //   console.log('addmarker')
      //   this.lushu.AddMarker(index)
      // }
    },
    /**
     * 设置平面坐标
     */
    setPixelPath() {
      const { trackMap, path } = this
      const map = trackMap
      const tmpArr = []
      path.forEach((p) => {
        tmpArr.push(map.pointToOverlayPixel(p))
      })
      this.pixelPath = [...tmpArr]
    },
    // 判断点是否在线段上
    containStroke(x0, y0, x1, y1, lineWidth, x, y) {
      if (lineWidth === 0) {
        return false
      }
      var _l = lineWidth
      var _a = 0
      var _b = x0
      // Quick reject
      if (
        (y > y0 + _l && y > y1 + _l) ||
        (y < y0 - _l && y < y1 - _l) ||
        (x > x0 + _l && x > x1 + _l) ||
        (x < x0 - _l && x < x1 - _l)
      ) {
        return false
      }

      if (x0 !== x1) {
        _a = (y0 - y1) / (x0 - x1)
        _b = (x0 * y1 - x1 * y0) / (x0 - x1)
      } else {
        return Math.abs(x - x0) <= _l / 2
      }
      var tmp = _a * x - y + _b
      var _s = (tmp * tmp) / (_a * _a + 1)
      return _s <= ((_l / 2) * _l) / 2
    },
    handlePolylineClick(e, strokeColor) {
      console.log('handlePolylineClick')
      // this.trackType = strokeColor
      // this.curInfoPoint = e.point
      // this.curInfoTitle = '作业状态'
      // this.showInfo = true
      const { pixelPath, containStroke, lineWidth, trackMap, setPixelPath, toPoint, curStatus } = this
      const map = trackMap
      setPixelPath()
      for (var j = 0; j < pixelPath.length; j++) {
        var beginPt = pixelPath[j]
        if (pixelPath[j + 1] === undefined) {
          return
        }
        var endPt = pixelPath[j + 1]
        var curPt = map.pointToOverlayPixel(e.point)
        var isOnLine = containStroke(beginPt.x, beginPt.y, endPt.x, endPt.y, lineWidth, curPt.x, curPt.y)
        if (isOnLine) {
          toPoint(j, curStatus)
          // 通知进度条位置变更
          window.PubSub.publish('slide', j)
          return
        }
      }
    },
    // 初始化历史轨迹播放
    initHistoryTrackPlay(data, fn) {
      const { trackMap } = this
      const map = trackMap
      const ov = map.getOverlays()
      console.log('ov', ov)
      ov.forEach((p) => {
        if (!p.Wa) {
          map.removeOverlay(p)
        }
      })
      setTimeout(() => {
        map && map.setViewport(data)
        // if (data.length > 0) {
        //   const point = new this.trackBMap.Point(data[0].lng, data[0].lat)
        //   this.trackMap.panTo(point)
        // }
        console.log('initLushu')
        this.initLushu(data)
        fn && fn()
      }, 100)
      this.$forceUpdate()
    },
    // 初始化历史轨迹播放,不清除Overlay
    initHistoryTrackPlay2(data, fn) {
      setTimeout(() => {
        console.log('initLushu')
        this.initLushu(data)
        fn && fn()
      }, 100)
      this.$forceUpdate()
    },
    handleMouseover(e, strokeColor) {
      console.log('e', e)
      this.trackType = strokeColor
      this.curInfoPoint = e.point
      this.curInfoTitle = '作业状态'
      this.showInfo = true
      console.log(strokeColor)
    },
    handleMouseout() {
      this.showInfo = false
    },
    handleSpeedhange(value) {
      console.log('通知更新速度')
      // window.PubSub.publish('speed', value)
      // this.lushu._setOptions({
      //   speed: 100 * value
      // })
    },
    setMarkerPositionAndRotation() {
      this.lushu && this.lushu.setMarkerPositionAndRotation()
    },
    // 分段绘制轨迹线
    drawTrackPolyline() {
      // 移除上次绘制的折线覆盖物
      this.trackPloylineOverlays.forEach((p) => {
        this.trackMap.removeOverlay(p)
      })
      this.trackPloylineOverlays.length = 0
      // 基于分组绘制折线
      let pointCount = 0
      this.currentTrackGroupArray.forEach((p, idx) => {
        const params = p.normal ? this.normalTrackPolylineParams : this.abNormalTrackPolylineParams
        const points = p.normal ? p.normal : p.abNormal
        // 此处如果增加icons参数，则会报错：VM44:1 Uncaught TypeError: i.$p is not a function
        // https://lbsyun.baidu.com/jsdemo3.0.htm#js3_6 示例绽放也会报错
        pointCount += points.length
        if (this.isShowSignal) {
          this.drawTrackPolylineWithSignal(points, { ...params })
        } else {
          const overlay = new this.trackBMap.Polyline(points, params)
          overlay.addEventListener('click', this.handlePolylineClick.bind(this))
          this.trackMap.addOverlay(overlay)
          this.trackPloylineOverlays.push(overlay)
        }
      })
      console.log(`本次轨迹添加覆盖物数量=${this.trackPloylineOverlays.length},总点位数量=${pointCount}`)
    },
    // 绘制带信号轨迹线
    drawTrackPolylineWithSignal(data, trackPolylineParams) {
      const turf = window.turf
      const newData = []
      let lastMidPoint = null
      for (let k = 0, n = data.length; k < n; k++) {
        // 当k=n-1时，点位m与k点位入栈，包含颜色
        const count = data[k].satelliteNum
        const color = getColor(count)
        const params = color ? Object.assign(trackPolylineParams, { strokeColor: color }) : trackPolylineParams
        if (k === n - 1) {
          if (lastMidPoint) {
            const pointWithColor = {
              data: [lastMidPoint, new this.BMap.Point(data[k].lng, data[k].lat)],
              params: params,
              count: count
            }
            newData.push(pointWithColor)
          }
        } else if (k === 0) {
          // 当k=0时，求0-1中间点m，点位0-m入栈，包含颜色
          const startPoint = new this.BMap.Point(data[k].lng, data[k].lat)
          const point1 = turf.point([data[k].lng, data[k].lat])
          const point2 = turf.point([data[k + 1].lng, data[k + 1].lat])
          const tmPoint = turf.midpoint(point1, point2).geometry.coordinates
          const midPoint = new this.BMap.Point(tmPoint[0], tmPoint[1])
          const pointWithColor = {
            data: [startPoint, midPoint],
            params: params,
            count: count
          }
          newData.push(pointWithColor)
          lastMidPoint = midPoint
        } else {
          // 中间点位
          const point1 = turf.point([data[k].lng, data[k].lat])
          const point2 = turf.point([data[k + 1].lng, data[k + 1].lat])
          const tmPoint = turf.midpoint(point1, point2).geometry.coordinates
          const midPoint = new this.BMap.Point(tmPoint[0], tmPoint[1])
          const pointWithColor = {
            data: [lastMidPoint, midPoint],
            params: params,
            count: count
          }
          newData.push(pointWithColor)
          lastMidPoint = midPoint
        }
      }

      // 信号最大最小差小于5，则取平均值
      const limit = 2
      if (data.length === 0) {
        return
      }
      const tmpData = []
      let min = newData[0].count
      let max = min
      newData.forEach((p) => {
        const c = p.count
        if (c < min) {
          min = c
        }
        if (c > max) {
          max = c
        }
        if (max - min <= limit) {
          tmpData.push(...p.data)
        } else {
          p.params.strokeColor = getColor(Math.floor((min + max) / 2))
          const overlay = new this.trackBMap.Polyline(tmpData, p.params)
          overlay.addEventListener('click', this.handlePolylineClick.bind(this))
          this.trackMap.addOverlay(overlay)
          this.trackPloylineOverlays.push(overlay)
          tmpData.length = 0
          min = c
          max = c
          tmpData.push(...p.data)
        }

        // const overlay = new this.trackBMap.Polyline(p.data, p.params)
        // overlay.addEventListener('click', this.handlePolylineClick.bind(this))
        // this.trackMap.addOverlay(overlay)
        // this.trackPloylineOverlays.push(overlay)
      })
    },
    // 轨迹数据分组
    // 上下两个点位时差超过20分钟，那么不绘制轨迹折线，丢失这一段轨迹线
    // moment(endTime).diff(this.moment(startTime), 'minutes')
    parseTrackData(data) {
      console.log('parseTrackData')
      // 基于正常点与异常点对轨迹数据进行分组
      const trackGroupArray = []
      const normalTrackData = []
      const abNormalTrackData = []

      // 计算当前点位到目标点位的距离
      // 1 2 3
      // for (let k = 0, n = data.length; k < n; k++) {
      //   const p2 = new this.BMap.Point(data[], lat)
      //   data[k+1].distance =  this.getDistanceByTwoPoint(lastPoint, currentPoint)
      // }
      // data[0].status = 0
      // data[1].status = 0
      // data[2].status = 2
      // data[3].status = 0
      // data[4].status = 0
      // 02/20
      // 020/022/220/002/200/00200
      // const t1 = '2022-04-18 00:00:00'
      // const t2 = '2022-04-18 00:00:30'
      // const d = moment(t2).diff(moment(t1), 'minutes')
      // 上一个轨迹点时间
      // data = [
      //   {
      //     lng: 80.24149438000808,
      //     lat: 41.25913866118828,
      //     time: '2022-04-18 00:00:00',
      //     status: 0
      //   },
      //   {
      //     lng: 80.24431505930941,
      //     lat: 41.25859634857784,
      //     time: '2022-04-18 00:20:00',
      //     status: 0
      //   },
      //   {
      //     lng: 80.23810978486797,
      //     lat: 41.25048038172061,
      //     time: '2022-04-18 00:40:00',
      //     status: 0
      //   },
      //   {
      //     lng: 80.23732095646025,
      //     lat: 41.24812294508932,
      //     time: '2022-04-18 01:00:00',
      //     status: 0
      //   },
      //   {
      //     lng: 80.23573624168912,
      //     lat: 41.24314531230863,
      //     time: '2022-04-18 01:10:00',
      //     status: 0
      //   },
      //   {
      //     lng: 80.23481997006895,
      //     lat: 41.24033810314032,
      //     time: '2022-04-18 01:20:00',
      //     status: 2
      //   }
      // ]
      // 00:00:00 00:10:00 断 00:40:00 00:50:00
      let lastPoint = data.length > 0 ? data[0] : {}
      data.forEach((p, idx) => {
        // 当前轨迹点时间
        const currentTime = p.time

        // 不同轨迹点
        if (lastPoint.time !== currentTime) {
          // 判断两个点位之间的时间间隔是否超过20分钟
          if (moment(currentTime).diff(moment(lastPoint.time), 'minutes') >= 10) {
            // 复位：重新开始重复代码1
            // 轨迹播放时，上一个位置直接跳到当前位置
            // 注意此处的data要与轨迹播放为同一个数组对象，否则此处改动无效
            lastPoint.jumpNext = true
            if (normalTrackData.length > 1) {
              // 加入正常分组
              trackGroupArray.push({
                normal: [...normalTrackData]
              })
              normalTrackData.length = 0
            }
            if (abNormalTrackData.length > 1) {
              trackGroupArray.push({
                abNormal: [...abNormalTrackData]
              })
              abNormalTrackData.length = 0
            }

            // 判断当前点位与下一个点位时间差是否超过20分钟，如果超过，则忽略当前点位
            if (idx + 1 < data.length) {
              if (moment(data[idx + 1].time).diff(moment(p.time), 'minutes') >= 10) {
                return
              }
            }
          }
          // 当前坐标作为第一个坐标点
          lastPoint = p
        }
        const point = new this.trackBMap.Point(p.lng, p.lat)
        // 模拟添加卫量数量
        point.satelliteNum = p.satelliteNum
        // normalTrackData.push(point)
        // 轨迹分组
        if (p.status === 2) {
          // 当前点位异常
          if (normalTrackData.length === 1) {
            // 如果只有一个正常点位，则将此正常点位加入到异常入栈
            abNormalTrackData.push(normalTrackData[0])
            // 重置正常分组
            normalTrackData.length = 0
          } else if (normalTrackData.length > 1) {
            // 加入正常分组
            trackGroupArray.push({
              normal: [...normalTrackData]
            })
            // 上一个正常点要加入异常栈,正常点要与异常点连接
            abNormalTrackData.push(normalTrackData[normalTrackData.length - 1])
            // 重置正常分组
            normalTrackData.length = 0
          }
          // 异常点位入栈
          abNormalTrackData.push(point)
        } else if (p.status <= 1) {
          // 当前点位正常
          if (abNormalTrackData.length > 0) {
            // 已有异常点位
            abNormalTrackData.push(point)
            // 加入异常分组
            trackGroupArray.push({
              abNormal: [...abNormalTrackData]
            })
            // 重置异常分组
            abNormalTrackData.length = 0
          }
          normalTrackData.push(point)
        } else {
          normalTrackData.push(point)
        }
      })

      // 重复代码2
      if (normalTrackData.length > 1) {
        // 加入正常分组
        trackGroupArray.push({
          normal: [...normalTrackData]
        })
        normalTrackData.length = 0
      }
      if (abNormalTrackData.length > 1) {
        trackGroupArray.push({
          abNormal: [...abNormalTrackData]
        })
        abNormalTrackData.length = 0
      }
      return trackGroupArray
    }
  }
}
